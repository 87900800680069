:root {
  --width: 1040px;
}

body {
  font-family: "IBM Plex Mono", monospace;
  margin: 0;
  color: var(--secondary);
}

table {
  margin: 0 auto;
  width: min-content;
}

td {
  outline: 2px solid var(--secondary);
}

.header, #impact {
  height: 450px;
}

td:has(#abstract) {
  padding: 0 !important;
  height: 550px;
}

tr:first-child {
  height: 0;
  box-sizing: border-box;
}

tr:nth-child(2) {
  height: 60px !important;
  box-sizing: border-box;
}

#abstract {
  height: 559px;
  overflow: hidden;
  margin: -1px 0 -5px;
}

#abstract {
  filter: invert(1);
}

#impact {
  padding: 0 20px;
}

.header {
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px 25px 30px;
}

h1 {
  font-family: "Lora", sans-serif;
  font-size: 3rem;
  font-weight: 400;
  margin: 0;
}

#register-by, #event-date {
  font-size: 2rem;
  width: 60%;
}

#register-by {
  text-align: right;
  margin-left: auto;
}

h2 {
  font-weight: 300;
  font-style: italic;
  font-size: 2rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

td:has(.extra-info) {
  padding: 0;
}

.extra-info {
  display: flex;
  justify-content: space-evenly;
}

h4 {
  font-size: 1.5rem;
  font-weight: 300;
  margin: 0;
  padding: 20px 40px;
  box-sizing: border-box;
  text-align: center;
  line-height: 1.5;
}

/* .extra-info > h4:first-child {
  border-right: 2px solid var(--secondary);
} */

td:has(h3) {
  background-color: var(--secondary);
}

h3 {
  color: var(--primary);
  text-align: center;
  font-size: 2rem;
  margin: 15px 0;
}

td:has(p) {
  padding: 40px 30px 0;
}

p {
  font-size: 1.3rem;
  line-height: 1.75;
  font-weight: 300;
  margin: 0 0 40px;
  text-align: justify;
}

@media (max-width: 1079px) {
  #header {
    font-size: 2.8rem;
  }

  td:has(#abstract) {
    height: 450px;
  }

  #abstract {
    height: 454px;
  }

  .header, #impact {
    height: 350px;
  }

  .header {
    width: 19.1rem;
    padding: 10px 20px 30px;
  }

  #register-by, #event-date {
    font-size: 1.5rem;
  }

  #impact {
    padding: 0 15px;
  }

  h2 {
    font-size: 1.75rem;
  }

  h4 {
    font-size: 1.3rem;
  }

  p {
    font-size: 1.25rem;
  }
}

@media (max-width: 879px) {
  #header {
    font-size: 1.9rem;
  }

  #register-by, #event-date {
    font-size: 1.4rem;
  }

  td:has(#abstract) {
    height: 350px;
  }

  #abstract {
    height: 100%;
  }

  .header, #impact {
    height: 250px;
  }
  
  tr:nth-child(2) {
    height: 50px !important;
  }

  .header {
    width: 15.9rem;
    padding: 10px 20px 30px;
  }

  #impact {
    padding: 0 10px;
  }

  h2, h3 {
    font-size: 1.5rem;
  }

  h4 {
    font-size: 1.2rem;
  }

  p {
    font-size: 1rem;
  }
}

@media (max-width: 699px) {
  table {
    width: 550px;
  }

  #abstract {
    width: 150px;
  }

  td:has(#abstract) {
    height: 220px;
    width: 150px;
  }

  .header, #impact {
    height: 180px;
  }

  .header {
    width: 200px;
  }

  #header {
    font-size: 1.5rem;
    width: 300px;
  }

  #register-by, #event-date {
    font-size: 1.2rem;
  }

  #register-by {
    margin-left: 150px;
  }

  #registration-message h2 {
    font-size: 0.9rem;
  }
  
  tr:nth-child(2) {
    height: 25px !important;
  }

  .header {
    width: 12.7rem;
    padding: 0 15px;
  }

  h1 {
    font-size: 2rem;
  }

  #impact {
    padding: 8px 5px 0;
  }

  td:has(#impact) {
    width: 0;
  }

  h2, h3 {
    font-size: 1.25rem;
  }

  h4 {
    font-size: 0.9rem;
  }

  td:has(p) {
    padding-top: 20px;
  }

  p {
    font-size: 0.9rem;
    margin-bottom: 20px;
  }
}

@media (max-width: 569px) {
  table {
    width: 400px;
  }

  #header {
    font-size: 1rem;
    width: 150px;
  }

  #register-by, #event-date {
    font-size: 1rem;
  }

  td:has(#abstract) {
    height: 250px;
  }

  #abstract {
    width: 120px;
    object-fit: cover;
  }

  .header, #impact {
    height: 150px;
  }
  
  tr:nth-child(2) {
    height: 30px !important;
  }

  .header {
    width: 170px;
  }

  #event-date, #register-by {
    font-size: 0.8rem;
  }

  #register-by {
    margin-left: 65px;
    margin-top: 20px;
  }

  h1 {
    font-size: 1.5rem;
  }

  h2 {
    font-size: 0.9rem;
  }

  h3 {
    font-size: 1rem;
    margin: 8px 0;
  }

  h4 {
    font-size: 0.78rem;
  }

  p {
    font-size: 0.8rem;
  }
}

@media (max-width: 409px) {
  table {
    width: 320px;
  }

  #register-by, #event-date {
    font-size: 0.8rem;
  }

  td:has(#abstract) {
    height: 200px;
    width: 80px;
  }

  .header {
    margin-left: -5px;
  }

  #header {
    font-size: 0.8rem;
    width: 170px;
  }

  #event-date, #register-by {
    font-size: 0.7rem;
  }

  #register-by {
    margin-top: 0;
    margin-left: 60px;
  }

  #abstract {
    width: 80px;
  }

  .header, #impact {
    height: 120px;
  }

  .header {
    width: 8.3rem;
  }

  h1 {
    font-size: 1.3rem;
  }

  h2 {
    font-size: 0.8rem;
  }

  .extra-info {
    display: block;
  }

  .extra-info > h4:first-child {
    border-right: none;
    border-bottom: 2px solid var(--secondary);
  }

  h4 {
    font-size: 0.78rem;
    padding: 10px 5px;
  }

  footer {
    padding-bottom: 10px;
  }
}

@media (max-width: 339px) {
  #abstract, td:has(#abstract) {
    width: 65px;
  }
}

/* Nav + subpage widths */

@media (max-width: 1079px) {
  :root {
      --width: 850px;
  }
}
@media (max-width: 879px) {
  :root {
      --width: 675px;
  }
}
@media (max-width: 699px) {
  :root {
      --width: 550px;
  }
}
@media (max-width: 569px) {
  :root {
      --width: 395px;
  }
}

@media (max-width: 409px) {
  :root {
      --width: 320px;
  }
}
@media (max-width: 339px) {
  :root {
      --width: 305px;
  }
}