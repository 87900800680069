.steps {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    width: calc(var(--width) - 5px);
    margin: 40px auto;
}

.step {
    border: 2px solid var(--secondary);
    width: 45%;
}

.step * {
    text-align: left;
    width: 100% !important;
}

.step h4 {
    font-weight: 600;
    padding: 0;
    line-height: normal;
    margin: 20px 20px 10px;
}

.step p {
    margin: 0 !important;
    padding: 0 20px 20px;
    box-sizing: border-box;
}

@media (max-width: 699px) {
    .step {
        width: 47%;
    }
}

@media (max-width: 569px) {
    .step {
        width: 100%;
    }
}