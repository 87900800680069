nav {
    margin: 20px auto;
    width: var(--width);
}

.nav-links {
    display: flex;
    justify-content: space-between;
}

.nav-links a {
    text-decoration: none;
    color: var(--secondary) !important;
    opacity: 0.7;
    transition-duration: .2s;
    font-size: 1rem;
}

.nav-links a:hover {
    opacity: 1;
}

@media (max-width: 569px) {
    nav, footer {
        margin: 10px auto !important;
    }

    .nav-links a, footer {
        font-size: 0.8rem;
    }
}