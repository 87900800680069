:root {
    --primary: #000000;
    --secondary: #fff;
}

body {
    background-color: var(--primary);
}

.App > *:not(.Home) h1 {
    letter-spacing: 20px;
    font-family: "IBM Plex Mono", monospace;
    width: var(--width);
    text-align: center;
    margin: 10px auto 20px;
    font-weight: 800;
    font-size: 2rem;
}

.App > *:not(.Home) p {
    width: calc(var(--width) + 5px);
    margin: 0 auto 30px;
}

.App {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.App > div {
    flex: 1;
}

footer {
    width: var(--width);
    height: 20px;
    text-align: center;
    opacity: 0.7;
    margin: 20px auto;
}

a {
    color: var(--secondary) !important;
}